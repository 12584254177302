import config from "../config";

export const fakeAPIProducts = "https://fakestoreapi.com/products";

export const botID = {
  STRUCTURED: "structured",
  UN_STRUCTURED: "unstructured",
  QUERY: "bot",
  GUIDELINE_PAGE_ID: "page_csrd_guideline",
};

export const plotColors = {
  red: "#ed4319",
  orange: "#f4a347",
  green: "#a3c388",
  blue: "#1a99aa",
  darkBlue: "#103c46",
  greenAlt: "#88ba01",
};

export const treeGraphColors = {
  root: "#ff7a41",
  parents: "#f4a347",
  nodes: "#4f71be",
  others: "#a3c388",
};

export const chartCommonTypo = {
  global: {
    fontFamily: config.fontFamily,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#e0e0e0",
  },
  chartTitle: {
    font: config.fontFamily,
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 500,
    color: "#f5f5f5",
  },
  axisTitle: {
    font: config.fontFamily,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#f5f5f5",
    fontWeight: 500,
    letterSpacing: "0.5px",
  },
};

export const chartOptions = {
  font: config.fontFamily,
  typography: {
    ...chartCommonTypo.global,
  },
  colors: ["#f4a347", "#ed4319", "#88ba01", "#a3c388", "#1a99aa"],
  colorsV2: ["#0D4C92", "#59C1BD", "#7EC384", "#6AA5A9"],
  title: {
    text: undefined,
    style: {
      ...chartCommonTypo.chartTitle,
    },
  },
  xAxis: {
    lineWidth: 0,
    tickColor: "#818181",
    title: {
      text: "x Axis title",
      style: {
        ...chartCommonTypo.axisTitle,
      },
      offset: 60,
    },
    labels: {
      style: {
        ...chartCommonTypo.global,
      },
    },
  },
  yAxis: {
    gridLineDashStyle: "Dot",
    gridLineColor: "#888888",
    title: {
      text: "Y Axis title",
      style: {
        ...chartCommonTypo.axisTitle,
      },
      offset: 60,
    },
    labels: {
      style: {
        ...chartCommonTypo.global,
      },
    },
  },
  tooltip: {
    padding: 10,
    backgroundColor: "#ffffff",
    borderRadius: 4,
    //borderColor: '#cccccc',
    style: {
      ...chartCommonTypo.global,
      color: "#333333",
    },
  },
  legend: {
    itemStyle: {
      ...chartCommonTypo.global,
      fontWeight: "400",
    },
    verticalAlign: "top",
  },
  export: {
    buttons: {
      contextButton: {
        symbolSize: 14,
        symbolStrokeWidth: 1,
        // symbolFill: "#cccccc",
        // symbolStroke: "#cccccc",
        x: 0,
        y: -7,
        // theme: {
        //   fill: "#454545",
        // },
      },
    },
  },
};

export const defaultBotSettings = {
  logo: {
    value: "None",
    files: "https://www.ongil.ai/wp-content/themes/ongil/images/octo_white.png",
  },
  logo_title: { value: "Octo", files: "" },
  bot_favicon: {
    value: "None",
    files:
      "https://www.ongil.ai/wp-content/uploads/2022/07/cropped-favicon-512x512-1-192x192.png",
  },
  user_favicon: {
    value: "None",
    files: "",
  },
};

export const mainCardTypes = {
  COMPONENT_WRAPPER_CARD: "COMPONENT_WRAPPER_CARD",
  COMPONENT_WRAPPER: "COMPONENT_WRAPPER",
};

export const chatFormPlaceholder = "Type your queries here...";
