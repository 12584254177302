import { combineReducers } from "@reduxjs/toolkit";
import appReducer from "./app/appSlice";
import quantReducer from "./quantitative/quantSlice";
import qualitativeReducer from "./qualitative/qualitativeSlice";
import queryReducer from "./query/querySlice";

const allReducer = combineReducers({
  app: appReducer,
  quantitative: quantReducer,
  qualitative: qualitativeReducer,
  query: queryReducer,
});

// Initial States for each reducer
const initialReducerState = {
  app: appReducer(undefined, {}),
  quantitative: quantReducer(undefined, {}),
  qualitative: qualitativeReducer(undefined, {}),
  query: queryReducer(undefined, {}),
};

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    // Reset all slices to their initial state except 'isBotSettingLoading'
    let updatedInitialState = {};
    Object.keys(initialReducerState).map((reducer) => {
      if (reducer === "app") {
        updatedInitialState[reducer] = {
          ...initialReducerState[reducer],
          isBotSettingLoading: !state[reducer].isBotSettingLoading,
        };
      } else {
        updatedInitialState[reducer] = initialReducerState[reducer];
      }
    });
    return updatedInitialState;
  }
  // Delegate state updates to the combined reducers
  return allReducer(state, action);
};

export default rootReducer;
