import HomeIcon from "@mui/icons-material/Home";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import AssessmentIcon from "@mui/icons-material/Assessment";
import CampaignIcon from "@mui/icons-material/Campaign";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import { botID } from "../constants";

export const QNChildItems = [
  // {
  //   id: "home",
  //   title: "Query",
  //   url: "/quant-analysis/query",
  //   icon: HomeIcon,
  // },
  {
    id: "faq",
    title: "FAQ",
    url: "/quantitative-analysis/faq",
    icon: AnalyticsIcon,
  },
  {
    id: "interactive",
    title: "Interactive Mode",
    url: "/quantitative-analysis/interactive",
    icon: AssessmentIcon,
  },
  {
    id: "insights",
    title: "Insights",
    url: "/quantitative-analysis/insights",
    icon: CampaignIcon,
  },
];

// Quantitative Menus
export const QNMenuItems = [
  {
    id: botID.STRUCTURED,
    title: "Quant. Analysis",
    isChild: false,
    childList: QNChildItems,
    url: "/quantitative-analysis",
  },
];

// Qualitative Menus
export const QLMenuItems = [
  {
    id: botID.UN_STRUCTURED,
    title: "Quali. Analysis",
    url: "/qualitative-analysis",
    isChild: false,
    childList: [],
  },
];

// Azure Bot
export const AZMenuItems = [
  {
    id: botID.QUERY,
    title: "Query Mode",
    url: "/query-mode",
    isChild: false,
    childList: [],
  },
];

// export const MenuItems = {
//   bot: AZMenuItems,
//   structured: QNMenuItems,
//   unstructured: QLMenuItems,
// };

export const PageMenuItems = [
  {
    id: botID.GUIDELINE_PAGE_ID,
    title: "Guideline Overview",
    url: "/guideline-overview",
    isChild: false,
    childList: [],
  },
];

export const MenuItems = [
  {
    id: botID.STRUCTURED,
    title: "Quant. Analysis",
    isChild: true,
    childList: QNChildItems,
    url: "/quantitative-analysis",
  },
  {
    id: botID.UN_STRUCTURED,
    title: "Quali. Analysis",
    url: "/qualitative-analysis",
    isChild: false,
    childList: [],
  },
  {
    id: botID.QUERY,
    title: "Query Mode",
    url: "/query-mode",
    isChild: false,
    childList: [],
  },
  ...PageMenuItems,
];

// Filter Menu Item by Modes
export const filterMenuItemsByModes = (modes) => {
  let tempArr = [];
  if (modes && modes.length) {
    tempArr = modes.map((id) => MenuItems.find((menu) => menu.id === id));
  }
  return tempArr;
};
