import React, { lazy } from "react";
import { Routes, Navigate, Route } from "react-router-dom";
import config from "../config";
import Loadable from "../components/Loadable";
import MainLayout from "../layouts/MainLayout";
import PrivateRoute from "../components/PrivateRoute";
import MinimalLayout from "../layouts/MinimalLayout";

// Auth Pages
const LoginPage = Loadable(lazy(() => import("../views/auth/Login")));

// View Pages
const QuantAnalysisPage = Loadable(
  lazy(() => import("../views/QuantAnalysis/quant.page"))
);
const FAQPage = Loadable(lazy(() => import("../views/FAQ/faq.page")));

const QualiAnalysisPage = Loadable(
  lazy(() => import("../views/QualiAnalysis/quali.page"))
);

const QueryBotPage = Loadable(
  lazy(() => import("../views/QueryBot/query.page"))
);
const CSRDGuidlinePage = Loadable(
  lazy(() => import("../views/CSRDGuideline/guideline.page"))
);

//-----------------------|| ROUTING RENDER ||-----------------------//
const AppRoutes = () => {
  return (
    <Routes>
      {/* Main Routes */}
      <Route path="/" element={<Navigate replace to={config.defaultPath} />} />
      <Route
        path="query-mode"
        element={
          <PrivateRoute>
            <MainLayout />
          </PrivateRoute>
        }
      >
        <Route index element={<QueryBotPage />} />
      </Route>

      <Route
        path="quantitative-analysis"
        element={
          <PrivateRoute>
            <MainLayout />
          </PrivateRoute>
        }
      >
        <Route index element={<QuantAnalysisPage />} />
        <Route path="faq" element={<FAQPage />} />
      </Route>

      <Route
        path="qualitative-analysis"
        element={
          <PrivateRoute>
            <MainLayout />
          </PrivateRoute>
        }
      >
        <Route index element={<QualiAnalysisPage />} />
      </Route>

      <Route
        path="guideline-overview"
        element={
          <PrivateRoute>
            <MinimalLayout />
          </PrivateRoute>
        }
      >
        <Route index element={<CSRDGuidlinePage />} />
      </Route>

      {/* Auth Routes */}
      <Route path="/login" element={<LoginPage />} />
    </Routes>
  );
};

export default AppRoutes;
